$(document).ready(function ($) {

    $('body').on('input', 'input[data-behavior="uppercase"]', function () {
        $(this).val(function (index, value) {
            return value.toUpperCase();
        });
    });

    $('.rut-validate').on('input', function (e) {
        var rut = $(this).val();
        if (rut.length > 2) {
            var actual = rut.replace(/^0+/, "");
            if (actual != '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 == 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }

            $(this).val(rutPuntos);

        }
    });

    $('.phone-validate').on('input', function () {
        this.value = parseInt(this.value.replace(/[^\d]/, ''));
        if (isNaN(this.value)) {
            this.value = "";
        }
    });

    $('.cost-format').on('input', function () {
        number = $(this).val().replace(/[^\d]/g, '');
        number = getNumberWithDots(number);
        number = "$ " + number;
        $(this).val(number);
    });

    $('.stock-format').on('input', function () {
        number = $(this).val().replace(/[^\d]/g, '');
        number = getNumberWithDots(number);
        $(this).val(number);
    });

    $('.weight-format').on('input', function () {
        number = $(this).val().replace(/./g, '');
        number = $(this).val().replace(/[^\d]/g, '');
        number = getNumberWithDots(number);
        $(this).val(number);
    });

    $(document).on('input', '.money-format', (function (event) {
        $(this).val(function (index, value) {

            value = value
                .replace(/(?!\,)\D/g, "")
                .replace(/\,/g, "|")
                .replace(/\|/, ",")
                .replace(/\|/g, "");

            if (/,\d/g.test(value)) {
                var parts = value.split(',');
                var mantissa = [];
                for (var i = 0; i < parts[1].length; i++) {
                    mantissa.push(parts[1].charAt(i));
                    if (i === 1) {
                        break;
                    }
                }
                value = parts[0] + ',' + mantissa.join('');
            }

            return value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        });
    }));

    $(document).on('input', '.money-format-clp', (function (event) {
        $(this).val(function (index, value) {

            value = value
                .replace(/(?!\,)\D/g, "")
                .replace(/\,/g, "|")
                .replace(/\|/, ",")
                .replace(/\|/g, "");

            if (/,\d/g.test(value)) {
                var parts = value.split(',');
                value = parts[0];
            }

            return value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        });
    }));

    $('.margin-format').on('input', (function (event) {
        $(this).val(function (index, value) {

            value = value
                .replace(/(?!\,)\D/g, "")
                .replace(/\,/g, "|")
                .replace(/\|/, ",")
                .replace(/\|/g, "");

            if (/,\d/g.test(value)) {
                var parts = value.split(',');
                var mantissa = [];
                for (var i = 0; i < parts[1].length; i++) {
                    mantissa.push(parts[1].charAt(i));
                    if (i === 2) {
                        break;
                    }
                }
                value = parts[0] + ',' + mantissa.join('');
            }

            return value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        });
    }));

    function getNumberWithDots(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

});
